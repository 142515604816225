export const screens = {
	addEmail: {
		title: "Add email address",
	},
	alreadyMigrated: {
		button: "Access new platform",
		description: "Your account has already been migrated to the new platform. To access the new platform, please click on the button below.",
		title: "Account already migrated",
	},
	changePassword: {
		action: "Change Password",
		label: "Change your password",
		newPassword: "New password",
		newPasswordConfirmation: "New password confirmation",
	},
	creation: {
		acceptTermsConditions: "I agree to the terms and conditions",
		comments: "Comments",
		durationInfo: "The duration includes preparation and follow-up conversations with the person conducting the interview",
		every15Min: "Show every 15 min",
		every15MinShort: "Every 15 min",
		filters: "Interpreter preferences",
		interpretersInfo: {
			firstPart: "This icon ",
			secondPart: " indicates the number of potentially available interpreters. Interpreters will still need to accept or refuse your request based on actual availability",
		},
		myAppointment: "My appointment",
		noLanguage: "No language pairs are available in this main language. Please change your main language.",
		notSpeak: "This interpreter doesn't speak your current main language. Please change your main language or select another interpreter.",
		otherProfessional: "Other professional",
		participants: "Participants",
		proximity: "Nearest Interpreters",
		scheduleOverlay: {
			error: "An error occurred during the request",
			formNotCompleted: "Not all the requested information are filled",
			immediate: "{{interpreters}} interpreters will be notified",
			missingMandatoryFields: "Please select search parameters",
			modifyForm: "Please modify the search parameters",
			noInterpreterAvailable: "No interpreter is available",
			noSlots: "No slots available",
			waitingTime: "Please wait up to 10 min",
		},
		scheduling: {
			immediate: "Immediate",
			scheduled: "Scheduled",
			scheduling: "When?",
		},
		searchAddress: "Search an address",
		seeTermsConditions: "See terms and conditions",
		selectDate: {
			anotherDay: "Select another day",
			sundayWarning: "As a reminder, Sunday work is subject to authorization. If possible, we ask you to make your request during a working day",
		},
		selectLanguage: "Select a language",
		toXInterpreters: "Session sent to {{n}} interpreters",
	},
	feedback: {
		actions: {
			sessionCreation: "session creation",
			sessionSearch: "session search",
		},
		comments: "Comments",
		commentsPlaceholder: "Let us know what you think...",
		header: "Feedback",
		otaSubtitle: "A new minor update is available, please restart the application",
		otaTitle: "New minor update available",
		rating: "Rating",
		title: "Tell us about your experience with the {{action}}",
	},
	home: {
		availability: "Your immediate availability",
		book: "Book {{name}}",
		choose: "Choose a language",
		current: "Your current session",
		emptyMandate: "No session offers for now",
		emptyNotification: "No notifications",
		emptyUpcoming: "No upcoming session",
		find: "Find your interpreter",
		mandate: "Your sessions offers",
		sessionLanguages: "Session languages",
		unavailibilitiesUpdateReminder: {
			no: "No, take me to the calendar",
			subtitle: "Help us improve our service by having your calendar updated.",
			title: "Have you thought of updating your unavailabilities?",
			yes: "Yes, they are up to date",
		},
		unvalidated: "Session to validate",
		unvalidated_plural: "Sessions to validate",
		unvalidatedWarning: "Only the validated sessions will be paid",
		upcoming: "Your next session",
		updateBanner: {
			appStore: "the App Store",
			googlePlay: "the Google Play",
			later: "Later",
			subtitle: "There is a newer version available for download! Please update the app by visiting {{store}}",
			title: "New Version Available",
			update: "Update",
		},
		welcome: "Welcome, {{firstName}}",
	},
	imagePicker: {
		permissions: {
			settings: {
				camera: "To send photos, please allow Bhaasha to access the camera on your device settings",
				library: "To send media, please allow Bhaasha to access photos, media and files on your device settings",
			},
		},
	},
	notifications: {
		anotherDevice: "Another device is already configured to receive notifications for this account",
		configure: "Configure",
		permissions: {
			enable: "Enable push notifications",
			subtitle: "In order to receive push notifications, you first need to activate them",
		},
		replace: "Replace with this device",
	},
	overlappingSessions: {
		explanation: "The unavailability could not be logged since you have the following sessions:",
	},
	profile: {
		edit: "Edit profile",
		guide: "Guide & FAQ",
		knownIssues: "Known issues",
		slides: "Welcome slides",
		support: "Contact support",
		update: "Update profile",
	},
	recover: {
		label: "Recover your password",
	},
	salary: {
		fees: "Fees",
		monthIncomes: "Incomes of {{month}}",
	},
	shareSession: {
		title: "Share session",
		titleVideo: "Share video session link",
	},
	signIn: {
		banner: {
			bullet1: "Easier access to interpreters and session overview",
			bullet2: "Lightning fast booking process",
			bullet3: "Smooth profile and account management",
			subTitle: "An even better interface to make booking an interpreter as easy as it gets.",
			title: "New! Bhaasha just got more powerful",
		},
		iam: "I am an",
		label: "Login to continue",
		legacy: "Legacy app (temporary access)",
		login: "Login",
		requestAccess: {
			button: "Request access",
			title: "Don't have an account yet ?",
		},
		subAction: "Forgot your password ?",
	},
	signOut: {
		confirm: "Are you sure you want to sign out?",
	},
	sliders: {
		welcome: {
			calendar: {
				description: "Review your sessions and adjust your availability with granularity by adding events to block out time in your week",
				title: "A powerful calendar to plan your day",
			},
			checks: {
				description: "We hope you enjoy the new app experience!",
				title: "You're all set to go - and discover",
			},
			dots: {
				description: "With a focus on productivity and a new intuitive interface, Bhaasha's interpreter app has been reinvented for you",
				title: "Welcome to the professional platform for interpreters",
			},
			inbox: {
				description: "Change your status, quickly access your next session and receive your new requests, all from your inbox",
				title: "Your inbox, for an overview at your fingertips",
			},
			incomes: {
				description: "Manage your workload and track your progress with built-in administrative features",
				title: "Your statistics and accounts, at a glance",
			},
			notifications: {
				description: "Receive your new applications in real time and have instant visibility on all the details before you commit",
				title: "Your notifications, delivered at the right time",
			},
			validation: {
				description: "New validation process combines flexibility and accuracy to move on to your next session with confidence",
				title: "Validation of your sessions, simplified",
			},
		},
		welcomeRequester: {
			account: {
				description: "TODO",
				title: "TODO",
			},
			calendar: {
				description: "TODO",
				title: "TODO",
			},
			checks: {
				description: "We hope you enjoy the new app experience!",
				title: "You are ready to go!",
			},
			communication: {
				description: "TODO",
				title: "TODO",
			},
			languages: {
				description: "TODO",
				title: "TODO",
			},
			support: {
				description: "TODO",
				title: "TODO",
			},
			timeslots: {
				description: "TODO",
				title: "TODO",
			},
			welcome: {
				description: "TODO",
				title: "TODO",
			},
		},
		welcomeRequesterMobile: {
			calendar: {
				title: "A powerful calendar to manage your bookings",
			},
			communication: {
				title: "A complete solution for in person, phone, and  video",
			},
			home: {
				title: "High quality interpreters at your fingertips",
			},
			session: {
				title: "Clear and simple display of sessions",
			},
			welcome: {
				description: "A new and powerful interpreter booking interface",
				title: "Bhaasha client platform",
			},
		},
	},
	unavailability: {
		addressExplanation: "The address is used to calculate travel times for consecutive sessions. It doesn't impact the payment.",
	},
	videoSession: {
		confirmVideoExit: "Do you really want to exit?",
		copySupportNumber: "Copy support number",
		disableAudio: "Disable audio",
		disableBlur: "Disable blur",
		disableVideo: "Disable video",
		enableAudio: "Enable audio",
		enableBlur: "Enable blur",
		enableVideo: "Enable video",
		flipCamera: "Flip camera",
		joinMessage: "By entering the room, I confirm to be in a calm environment where I can guarantee privacy.\n\nValidation will be automatically conducted by the system after the session.",
		joinTitle: "Ready to join?",
		noParticipants: "No other participant in the call.",
		otherOptions: "Other options",
		participantsPending: "Waiting for the other participants...",
		selectDevice: "Select a device",
	},
};
