import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Button} from "../../../../components/buttons/button";
import {HeaderMenu} from "../../../../components/menus/header";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {Auth, removeAuth} from "../../../../utils/auth";
import {DEFAULT_SPACING, ENV, IS_INTERPRETER} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Log} from "../../../../utils/logs/logs";
import {linkToURL} from "../../../../utils/network/links";
import {ps} from "../../../../utils/switch";

const NEW_FRONTEND_URL = ps(ENV, {
	production: IS_INTERPRETER
		? "new-bhaasha-interpreter://app.interpreter.bhaasha.ch/"
		: "new-bhaasha-client://app.client.bhaasha.ch/",
	default: IS_INTERPRETER // for development and staging, we redirect to the staging URL
		? "new-bhaasha-interpreter://app.interpreter.staging.bhaasha.ch/"
		: "new-bhaasha-client://app.client.staging.bhaasha.ch/",
});

const redirectToNewFrontend = async (): Promise<void> => {
	await linkToURL(NEW_FRONTEND_URL).catch(Log.error());
	await Auth.logout();
};

const AlreadyMigrated = (): JSX.Element => {
	const {t} = useTranslation();

	return (
		<>
			<HeaderMenu center={t("screens:alreadyMigrated.title")}/>

			<View style={styles.container}>
				<Text>{t("screens:alreadyMigrated.description")}</Text>
				<Button
					type="primary"
					size="small"
					onPress={redirectToNewFrontend}
					text={t("screens:alreadyMigrated.button")}
				/>
			</View>
		</>

	);
};

const styles = StyleSheet.create({
	container: {
		gap: DEFAULT_SPACING,
		padding: DEFAULT_SPACING,
	},
});

export const AlreadyMigratedModal = (): JSX.Element => (
	<ModalWrapper>
		<AlreadyMigrated/>
	</ModalWrapper>
);
