import {ENV, IS_CLIENT} from "../../../utils/constants";
import {Log} from "../../../utils/logs/logs";
import {s} from "../../../utils/switch";

// Timeout for the request
const TIMEOUT = 2000;

const NEW_BACKEND_DEVELOPMENT_URL = "http://localhost:5001";
const NEW_BACKEND_STAGING_URL = "https://backend-587832592774.europe-west6.run.app";
const NEW_BACKEND_PRODUCTION_URL = "https://backend-366805409371.europe-west6.run.app";

const BASE_URL = s(ENV, {
	development: NEW_BACKEND_DEVELOPMENT_URL,
	staging: NEW_BACKEND_STAGING_URL,
	production: NEW_BACKEND_PRODUCTION_URL,
});

export const checkIsUserMigrated = async (identityId: string): Promise<boolean> => {
	const controller = new AbortController();
	const timeout = setTimeout(() => controller.abort(), TIMEOUT);

	if (!BASE_URL) {
		return false;
	}

	const params = new URLSearchParams({
		batch: "1",
		input: JSON.stringify({0: {json: {oldPersonId: identityId, isClientApp: IS_CLIENT}}}),
	});
	const ENDPOINT_URL = `${BASE_URL}/bhaasha-trpc/auth.alreadyMigrated?${params}`;

	try {
		const response = await fetch(
			ENDPOINT_URL,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
				signal: controller.signal,
			});

		// If the response is not ok, we return false (the user is not migrated)
		if (!response.ok) {
			return false;
		}

		const data = await response.json();
		return data[0].result.data.json;
	} catch (error) {
		if (error.name === "AbortError") {
			// If the request is aborted, we log it and return false (the user is not migrated)
			Log.error()("Request isUserMigrated timed out");
			return false;
		}
		return false;
	} finally {
		clearTimeout(timeout); // Clear the timeout as cleanup
	}
};
