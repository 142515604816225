import {screens as enUSScreens} from "../en-US/screens";

export const screens: typeof enUSScreens = {
	addEmail: {
		title: "Aggiungere un indirizzo e-mail",
	},
	alreadyMigrated: {
		button: "Vai alla nuova app",
		description: "Il tuo account è già stato migrato alla nuova piattaforma. Per accedere alla nuova piattaforma, fai clic sul pulsante sottostante.",
		title: "Account migrato",
	},
	changePassword: {
		action: "Cambiare la password",
		label: "Cambia la tua password",
		newPassword: "Nuova password",
		newPasswordConfirmation: "Conferma la nuova password",
	},
	creation: {
		acceptTermsConditions: "Accetto i termini e le condizioni",
		comments: "Commenti",
		durationInfo: "La durata comprende la preparazione e le conversazioni di follow-up con la persona che conduce il colloquio",
		every15Min: "Mostrare ogni 15 min",
		every15MinShort: "Ogni 15 min",
		filters: "Preferenze dell'interprete",
		interpretersInfo: {
			firstPart: "Il icona ",
			secondPart: " indica il numero di interpreti potenzialmente disponibili. Gli interpreti dovranno comunque accettare o rifiutare la richiesta in base all'effettiva disponibilità",
		},
		myAppointment: "Il mio appuntamento",
		noLanguage: "Non sono disponibili coppie di lingue in questa lingua principale. Si prega di cambiare la lingua principale.",
		notSpeak: "Questo interprete non parla la vostra lingua principale. Si prega di cambiare la lingua principale o di selezionare un altro interprete.",
		otherProfessional: "Altro professionista",
		participants: "Partecipanti",
		proximity: "Interpreti più vicini",
		scheduleOverlay: {
			error: "Si è verificato un errore durante la richiesta",
			formNotCompleted: "Non tutte le informazioni richieste sono compilate",
			immediate: "{{interpreters}} interpreti saranno avvisati",
			missingMandatoryFields: "Scegli i parametri di ricerca",
			modifyForm: "Si prega di modificare i parametri di ricerca",
			noInterpreterAvailable: "Nessun interprete è disponibile",
			noSlots: "Non ci sono slot disponibili",
			waitingTime: "Tempo di risposta stimato: 10 min",
		},
		scheduling: {
			immediate: "Immediata",
			scheduled: "Pianificata",
			scheduling: "Quando?",
		},
		searchAddress: "Cercare un indirizzo",
		seeTermsConditions: "Vedi termini e le condizioni",
		selectDate: {
			anotherDay: "Seleziona un altro giorno",
			sundayWarning: "Si ricorda che il lavoro domenicale è soggetto ad autorizzazione, pertanto, se possibile, si prega di effettuare la richiesta in un giorno lavorativo",
		},
		selectLanguage: "Selezionare un lingua",
		toXInterpreters: "Sessione inviata a {{n}} interpreti",
	},
	feedback: {
		actions: {
			sessionCreation: "la creazione della sessione",
			sessionSearch: "la ricerca di sessioni",
		},
		comments: "Commenti",
		commentsPlaceholder: "Condividi con noi la tua opinione...",
		header: "Feedback",
		otaSubtitle: "È disponibile un nuovo aggiornamento minore, riavviare l'applicazione",
		otaTitle: "Nuovo aggiornamento minore disponibile",
		rating: "Valutazione",
		title: "Ci parli della sua esperienza con {{action}}",
	},
	home: {
		availability: "La tua disponibilità immediata",
		book: "Prenotare {{name}}",
		choose: "Scegliere una lingua",
		current: "La tua sessione attuale",
		emptyMandate: "Nessuna sessione in questo momento",
		emptyNotification: "Nessuna notifica",
		emptyUpcoming: "Nessuna sessione imminente",
		find: "Trovare il vostro interprete",
		mandate: "Le tue richieste di sessione",
		sessionLanguages: "Lingue della sessione",
		unavailibilitiesUpdateReminder: {
			no: "No, portami al calendario",
			subtitle: "Aiutateci a migliorare il nostro servizio aggiornando il vostro calendario.",
			title: "Vi siete ricordati di aggiornare le assenze?",
			yes: "Sì, sono aggiornati",
		},
		unvalidated: "Sessione da convalidare",
		unvalidated_plural: "Sessioni da convalidare",
		unvalidatedWarning: "Solo le sessioni convalidate saranno pagate",
		upcoming: "Prossima sessione",
		updateBanner: {
			appStore: "l'App Store",
			googlePlay: "il Google Play",
			later: "Più tardi",
			subtitle: "È disponibile una nuova versione da scaricare! Aggiornate l'applicazione visitando {{store}}",
			title: "Nuova versione disponibile",
			update: "Aggiornamento",
		},
		welcome: "Buongiorno, {{firstName}}",
	},
	imagePicker: {
		permissions: {
			settings: {
				camera: "Per inviare foto, consentire a Bhaasha l'accesso alla fotocamera nelle impostazioni del dispositivo",
				library: "Per inviare i contenuti multimediali, consentire a Bhaasha di accedere a foto, contenuti multimediali e file sulle impostazioni del dispositivo",
			},
		},
	},
	notifications: {
		anotherDevice: "Un altro dispositivo è già configurato per ricevere notifiche per questo account",
		configure: "Configura",
		permissions: {
			enable: "Abilitare le notifiche push",
			subtitle: "Per ricevere le notifiche push, devi prima abilitarli",
		},
		replace: "Con questo apparecchio sostituire",
	},
	overlappingSessions: {
		explanation: "Non è stato possibile registrare l'indisponibilità poiché sono presenti le seguenti sessioni:",
	},
	profile: {
		edit: "Modificare il profilo",
		guide: "Guida & FAQ",
		knownIssues: "Problemi noti",
		slides: "Slides di benvenuto",
		support: "Contattare il supporto",
		update: "Aggiornare il profilo",
	},
	recover: {
		label: "Recuperare la password",
	},
	salary: {
		fees: "Tasse",
		monthIncomes: "Reddito {{month}}",
	},
	shareSession: {
		title: "Condividere la sessione",
		titleVideo: "Condividere il link della sessione video",
	},
	signIn: {
		banner: {
			bullet1: "Accesso facilitato agli interpreti e alla panoramica delle sessioni",
			bullet2: "Processo di prenotazione fulmineo",
			bullet3: "Gestione fluida del profilo e dell'account",
			subTitle: "Un'interfaccia ancora migliore per rendere la prenotazione di un interprete il più semplice possibile.",
			title: "Nuovo! Bhaasha è appena diventato più potente",
		},
		iam: "Sono un",
		label: "Accedi per continuare",
		legacy: "Vecchia app (accesso temporaneo)",
		login: "Login",
		requestAccess: {
			button: "Richiedi l'accesso",
			title: "Non avete ancora un conto ?",
		},
		subAction: "Hai dimenticato la tua password?",
	},
	signOut: {
		confirm: "Disconnettersi?",
	},
	sliders: {
		welcome: {
			calendar: {
				description: "Rivedi le tue sessioni e regola la tua disponibilità con granularità aggiungendo eventi per bloccare il tempo nella tua settimana",
				title: "Un potente calendario per pianificare la tua giornata",
			},
			checks: {
				description: "Speriamo che vi piaccia la nuova esperienza dell'app!",
				title: "Sei pronto per iniziare - e scoprire",
			},
			dots: {
				description: "Con un focus sulla produttività e una nuova interfaccia intuitiva, l'app interprete di Bhaasha è stata reinventata per te",
				title: "Benvenuti nella piattaforma professionale per interpreti",
			},
			inbox: {
				description: "Cambia il tuo stato, accedi rapidamente alla tua prossima sessione e ricevi le tue nuove richieste, tutto dalla tua casella di posta elettronica",
				title: "La tua casella di posta, per una panoramica a portata di mano",
			},
			incomes: {
				description: "Gestisci il tuo carico di lavoro e segui i tuoi progressi con le funzioni amministrative integrate",
				title: "Le tue statistiche e i tuoi conti, a colpo d'occhio",
			},
			notifications: {
				description: "Ricevere le nuove applicazioni in tempo reale e avere una visibilità istantanea di tutti i dettagli prima di impegnarsi",
				title: "Le tue notifiche, consegnate al momento giusto",
			},
			validation: {
				description: "Il nuovo processo di convalida combina flessibilità e precisione per passare alla prossima sessione con fiducia",
				title: "Convalida delle vostre sessioni, semplificata",
			},
		},
		welcomeRequester: {
			account: {
				description: "TODO",
				title: "TODO",
			},
			calendar: {
				description: "TODO",
				title: "TODO",
			},
			checks: {
				description: "Speriamo che vi piaccia la nuova esperienza dell'app!",
				title: "Sei pronto per iniziare - e scoprire",
			},
			communication: {
				description: "TODO",
				title: "TODO",
			},
			languages: {
				description: "TODO",
				title: "TODO",
			},
			support: {
				description: "TODO",
				title: "TODO",
			},
			timeslots: {
				description: "TODO",
				title: "TODO",
			},
			welcome: {
				description: "TODO",
				title: "TODO",
			},
		},
		welcomeRequesterMobile: {
			calendar: {
				title: "Un potente calendario per gestire le prenotazioni",
			},
			communication: {
				title: "Una soluzione completa per sessioni di persona, telefoniche e video",
			},
			home: {
				title: "Interpreti di alta qualità a portata di mano",
			},
			session: {
				title: "Visualizzazione chiara e semplice delle sessioni",
			},
			welcome: {
				description: "Una nuova e potente interfaccia di prenotazione dell'interprete",
				title: "Piattaforma clienti Bhaasha",
			},
		},
	},
	unavailability: {
		addressExplanation: "L'indirizzo viene utilizzato per calcolare i tempi di percorrenza delle sessioni successive. Non ha alcuna influenza sulla retribuzione.",
	},
	videoSession: {
		confirmVideoExit: "Vuoi davvero andartene?",
		copySupportNumber: "Copiare il numero di supporto",
		disableAudio: "Disabilitare audio",
		disableBlur: "Disabilitare sfocatura",
		disableVideo: "Disabilitare video",
		enableAudio: "Abilitare audio",
		enableBlur: "Abilitare sfocatura",
		enableVideo: "Abilitare video",
		flipCamera: "Flipare telecamera",
		joinMessage: "Entrando nella stanza confermo di trovarmi in un ambiente tranquillo dove posso garantire la privacy.\n\nLa validazione sarà effettuata automaticamente dal sistema dopo la sessione.",
		joinTitle: "Vuoi partecipare?",
		noParticipants: "Non c'è nessun altro partecipante nella chiamata.",
		otherOptions: "Altre opzioni",
		participantsPending: "In attesa degli altri partecipanti...",
		selectDevice: "Selezionare un dispositivo",
	},
};
