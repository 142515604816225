import {Linking} from "react-native";
import {ENV, IS_INTERPRETER, IS_IOS, IS_WEB} from "../constants";
import {getKeys} from "../objects";
import {removeSpaces} from "../strings";

// using Linking.canOpen doesn't work for some reason... It returns false even when the app is installed...
export const linkToURL = async (url: string, self = false): Promise<unknown> => {
	const webURL = url.replace(/^.*:\/\//, "");
	const webUrlToOpen = `https://${webURL}`;
	try {
		if (IS_WEB) {
			return window.open(webUrlToOpen, self ? "_self" : "");
		}
		await Linking.openURL(url);
	} catch {
		return await Linking.openURL(webUrlToOpen);
	}
};

export const linkToAddress = (address: string): Promise<unknown> => {
	const addressQuery = address.split(" ").join("+");
	const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${addressQuery}`;
	return IS_IOS
		? Linking.canOpenURL("comgooglemaps://") // check if google maps app is available on device
			.then(gmInstalled =>
				gmInstalled
					? linkToURL(googleMapsURL)
					: linkToURL(`https://maps.apple.com/?q=${addressQuery}`),
			)
		: linkToURL(googleMapsURL); // on android, google maps link can be opened in others maps application
};

export const linkToCall = (number: string | undefined): Promise<unknown> =>
	linkToURL(`tel:${removeSpaces(String(number))}`);

export const linkToMail = (mail: string | undefined): Promise<unknown> =>
	linkToURL(`mailto:${String(mail)}`);

export const objectToUriParams = (object: {[key: string]: boolean | number | string}): string =>
	getKeys(object).map(
		(key, index) => `${index === 0 ? "" : "&"}${key}=${encodeURIComponent(object[key])}`,
	).join("");

export const linkToVideoCall = (channelId: string, token: string): string =>
	`https://${IS_INTERPRETER ? "interpreter" : "requester"}.${ENV === "staging"
		? "staging."
		: ""}bhaasha.com/connect?${objectToUriParams({channelId, token})}`;
