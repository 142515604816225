import {screens as enUSScreens} from "../en-US/screens";

export const screens: typeof enUSScreens = {
	addEmail: {
		title: "Ajouter une addresse E-mail",
	},
	alreadyMigrated: {
		button: "Accéder à la nouvelle application",
		description: "Votre compte a déjà été migré vers la nouvelle application. Pour accéder à la nouvelle application, veuillez cliquer sur le bouton ci-dessous.",
		title: "Compte déjà migré",
	},
	changePassword: {
		action: "Changer le mot de passe",
		label: "Changez votre mot de passe",
		newPassword: "Nouveau mot de passe",
		newPasswordConfirmation: "Confirmez le nouveau mot de passe",
	},
	creation: {
		acceptTermsConditions: "J'accepte les termes et conditions",
		comments: "Commentaires",
		durationInfo: "La durée comprend la préparation et les conversations de suivi avec la personne qui mène l'entretien",
		every15Min: "Montrer toutes les 15 min",
		every15MinShort: "Par 15 min",
		filters: "Profil de votre interprète",
		interpretersInfo: {
			firstPart: "Cette icône ",
			secondPart: " indique le nombre d'interprètes potentiellement disponibles. Les interprètes devront toujours accepter ou refuser votre demande en fonction de leur disponibilité réelle",
		},
		myAppointment: "Mon rendez-vous",
		noLanguage: "Aucune paire de langues n'est disponible dans cette langue principale. Veuillez changez votre langue principale.",
		notSpeak: "Cet interprète ne parle pas votre langue principale actuelle. Veuillez changez votre langue principale ou sélectionnez un autre interprète.",
		otherProfessional: "Autre professionnel",
		participants: "Participants",
		proximity: "Interprètes à proximité",
		scheduleOverlay: {
			error: "Une erreur s'est produite",
			formNotCompleted: "Veuillez remplir les informations manquantes",
			immediate: "{{interpreters}} interprète(s) seront notifié(s)",
			missingMandatoryFields: "Veuillez choisir les paramètres de la recherche",
			modifyForm: "Veuillez modifier les paramètres de recherche",
			noInterpreterAvailable: "Aucun interprète n'est disponible",
			noSlots: "Aucun créneau n'est disponible",
			waitingTime: "Temps d'attente: jusqu'à 10 min",
		},
		scheduling: {
			immediate: "Immédiat",
			scheduled: "Planifié",
			scheduling: "Quand?",
		},
		searchAddress: "Rechercher une adresse",
		seeTermsConditions: "Voir les termes et conditions",
		selectDate: {
			anotherDay: "Choisir un autre jour",
			sundayWarning: "Pour rappel, le travail le dimanche est soumis à autorisation. Dans la mesure du possible, nous vous prions de faire votre demande durant un jour ouvrable.",
		},
		selectLanguage: "Sélectionner une langue",
		toXInterpreters: "Session envoyée à {{n}} interprètes",
	},
	feedback: {
		actions: {
			sessionCreation: "la création de session",
			sessionSearch: "la recherche de sessions",
		},
		comments: "Commentaires",
		commentsPlaceholder: "Faites-nous part de votre avis...",
		header: "Feedback",
		otaSubtitle: "Une nouvelle mise à jour mineure est disponible, veuillez redémarrer l'application",
		otaTitle: "Nouvelle mise à jour mineure disponible",
		rating: "Note",
		title: "Quelle est votre expérience avec {{action}}?",
	},
	home: {
		availability: "Votre disponibilité immédiate",
		book: "Réserver {{name}}",
		choose: "Choisir une langue",
		current: "Votre session en cours",
		emptyMandate: "Aucune session pour le moment",
		emptyNotification: "Aucune notification",
		emptyUpcoming: "Aucune session à venir",
		find: "Trouvez votre interprète",
		mandate: "Vos offres de session",
		sessionLanguages: "Langues de la session",
		unavailibilitiesUpdateReminder: {
			no: "Non, menez-moi au calendrier",
			subtitle: "Aidez-nous à améliorer notre service en maintenant à jour votre calendrier.",
			title: "Avez-vous pensé à mettre à jour vos indisponibilités?",
			yes: "Oui, elles sont à jour",
		},
		unvalidated: "Session à valider",
		unvalidated_plural: "Sessions à valider",
		unvalidatedWarning: "Seules les sessions validées seront payées",
		upcoming: "Votre prochaine session",
		updateBanner: {
			appStore: "l'App Store",
			googlePlay: "le Google Play",
			later: "Plus tard",
			subtitle: "Il existe une version plus récente à télécharger ! Veuillez mettre à jour l'application en vous rendant sur {{store}}",
			title: "Nouvelle version disponible",
			update: "Mettre à jour",
		},
		welcome: "Bienvenue, {{firstName}}",
	},
	imagePicker: {
		permissions: {
			settings: {
				camera: "Pour envoyer des photos, veuillez authoriser Bhaasha à accéder à l'appareil photo sur les paramètres de ton appareil",
				library: "Pour envoyer des médias, veuillez authoriser Bhaasha à accéder aux photos, médias et fichiers sur les paramètres de ton appareil",
			},
		},
	},
	notifications: {
		anotherDevice: "Un autre appareil est déjà configuré pour recevoir les notifications pour ce compte",
		configure: "Configurer",
		permissions: {
			enable: "Activer les Notifications Push",
			subtitle: "Pour recevoir des notifications push, vous devez d'abord les activer",
		},
		replace: "Remplacer avec cet appareil",
	},
	overlappingSessions: {
		explanation: "L'indisponibilité n'a pas pu être enregistrée car vous avez les sessions suivantes:",
	},
	profile: {
		edit: "Éditer le profil",
		guide: "Guide & FAQ",
		knownIssues: "Problèmes connus",
		slides: "Slider de présentation",
		support: "Contacter le support",
		update: "Mettre à jour le profil",
	},
	recover: {
		label: "Récupérer votre mot de passe",
	},
	salary: {
		fees: "Honoraires",
		monthIncomes: "Revenus {{month}}",
	},
	shareSession: {
		title: "Partager la session",
		titleVideo: "Partager le lien de la session video",
	},
	signIn: {
		banner: {
			bullet1: "Un accès plus facile aux interprètes et à vos sessions",
			bullet2: "Processus de réservation rapide",
			bullet3: "Gestion de compte avancée",
			subTitle: "Trouvez votre interprète à travers une nouvelle interface plus performante et intuitive.",
			title: "Nouveau ! Bhaasha vient de devenir plus puissant",
		},
		iam: "Je suis",
		label: "Connectez-vous pour continuer",
		legacy: "Ancienne app (accès provisoire)",
		login: "Login",
		requestAccess: {
			button: "Demander un accès",
			title: "Pas encore de compte ?",
		},
		subAction: "Mot de passe oublié ?",
	},
	signOut: {
		confirm: "Êtes-vous sûr de vouloir vous déconnecter?",
	},
	sliders: {
		welcome: {
			calendar: {
				description: "Passez en revue vos sessions et ajustez vos disponibilités avec granularité en ajoutant des évènements pour bloquer des moments de votre semaine",
				title: "Un calendrier performant pour planifier votre journée",
			},
			checks: {
				description: "Nous espérons que vous apprécierez la nouvelle expérience de l'application!",
				title: "Vous êtes fin prêt à démarrer – et découvrir",
			},
			dots: {
				description: "Axée sur la productivité et munie d’une nouvelle interface intuitive, l’app pour interprètes de Bhaasha à été réinventée pour vous",
				title: "Bienvenue sur la plateforme professionnelle pour interprètes",
			},
			inbox: {
				description: "Changez votre statut, accédez rapidement à votre prochaine session et recevez vos nouvelles offres, tout depuis votre inbox",
				title: "Votre inbox, pour une vue d’ensemble à portée de main",
			},
			incomes: {
				description: "Gérez votre charge de travail et suivez votre progression avec les fonctionnalités administratives intégrées",
				title: "Vos statistiques et décomptes, en un clin d’œil",
			},
			notifications: {
				description: "Recevez vos nouvelles offres en temps réel et bénéficiez d'une visibilité instantanée sur tout les détails avant de vous engager",
				title: "Vos notifications, délivrées au bon moment",
			},
			validation: {
				description: "Le nouveau processus de validation combine flexibilité et précision pour passer à votre prochaine session avec confiance",
				title: "La validation de vos sessions, simplifiée",
			},
		},
		welcomeRequester: {
			account: {
				description: "Avec des fonctionnalités administratives avancées",
				title: "Gérez les demandeurs et données de votre compte",
			},
			calendar: {
				description: "Recherchez et consultez aisément toutes vos sessions futures et passées. Replanifiez, annulez ou créez un suivi en quelques clics.",
				title: "Un calendrier puissant, pour une vue d’ensemble",
			},
			checks: {
				description: "Nous espérons que vous apprécierez la nouvelle expérience de l'application!",
				title: "Vous êtes tout prêt à démarrer!",
			},
			communication: {
				description: "Parlez avec votre interprète en personne, par téléphone ou vidéo de manière sécurisée avec nos technologies de communication intégrées.",
				title: "Communiquez où que vous soyez, par le moyen adapté à la situation",
			},
			languages: {
				description: "Choisissez d’abord une langue pour créer une nouvelle session et trouver votre interprète.",
				title: "La nouvelle page d’accueil",
			},
			support: {
				description: "Retrouvez rapidement les ressources à votre disposition pour bien démarrer.",
				title: "Guide d’utilisation & support",
			},
			timeslots: {
				description: "Vous choisissez le profil de votre interprète – y compris son genre, pays d’origine et niveau de qualification – et planifiez à l’aide d’un calendrier intelligent. Les interprètes les plus proches de vous sont notifiés en premier.",
				title: "Trouvez le bon interprète, pour maintenant ou plus tard",
			},
			welcome: {
				description: "Avec une nouvelle interface plus performante et intuitive, trouvez votre interprète communautaire encore plus facilement et rapidement.",
				title: "Bienvenue sur la nouvelle plateforme",
			},
		},
		welcomeRequesterMobile: {
			calendar: {
				title: "Un calendrier puissant pour gérer vos réservations",
			},
			communication: {
				title: "Une solution complète pour les sessions en personne, par téléphone et par vidéo",
			},
			home: {
				title: "Des interprètes de grande qualité à portée de main",
			},
			session: {
				title: "Visualisation claire et simple des sessions",
			},
			welcome: {
				description: "Une nouvelle interface puissante de réservation d'interprètes",
				title: "Plateforme clients Bhaasha",
			},
		},
	},
	unavailability: {
		addressExplanation: "L'adresse est utilisée pour calculer les temps de trajet des sessions consécutives. Elle n'a pas d'incidence sur le paiement.",
	},
	videoSession: {
		confirmVideoExit: "Êtes-vous sûr de vouloir quitter?",
		copySupportNumber: "Copier le numéro du support",
		disableAudio: "Désactiver audio",
		disableBlur: "Désactiver flou",
		disableVideo: "Désactiver vidéo",
		enableAudio: "Activer audio",
		enableBlur: "Activer flou",
		enableVideo: "Activer vidéo",
		flipCamera: "Changer de caméra",
		joinMessage: "En entrant dans la salle, je confirme que je me trouve dans un environnement calme où je peux garantir le respect de la vie privée.\n\nLa validation sera effectuée automatiquement par le système après la session.",
		joinTitle: "Prêt à participer?",
		noParticipants: "Aucun autre participant dans l'appel.",
		otherOptions: "Autres options",
		participantsPending: "En attente des autres participants...",
		selectDevice: "Sélectionner un périphérique",
	},
};
